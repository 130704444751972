@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

:root {
  --font-family-main: "Roboto", sans-serif;

  // xs
  --font-size-xs: 12px;
  --font-line-xs: 12px;
  --font-xs: var(--font-size-xs) / var(--font-line-xs) var(--font-family-main);
  // s
  --font-size-s: 14px;
  --font-line-s: 20px;
  --font-s: var(--font-size-s) / var(--font-line-s) var(--font-family-main);

  // m
  --font-size-m: 16px;
  --font-line-m: 24px;
  --font-m: var(--font-size-m) / var(--font-line-m) var(--font-family-main);

  // l
  --font-size-l: 24px;
  --font-line-l: 29px;
  --font-l: var(--font-size-l) / var(--font-line-l) var(--font-family-main);

  // xl
  --font-size-xl: 28px;
  --font-line-xl: 34px;
  --font-xl: var(--font-size-xl) / var(--font-line-xl) var(--font-family-main);

  --font-weigth-bold: 500;
  --font-weigth-normal: 400;


  //colors
  --main-color: #1F211E;
  //--recon-primary-color: #3B57A0;
  //--primary-color: #41A62B;
  //--primary-color-shade: #378D24FF;
  --inverted-primary-color: #282828;
  --secondary-color: #898C87;
  --inverted-secondary-color: #FFF;
  --bg-color: #F5F5F5;
  --white-bg-color: #FFF;
  --gray100: #FAFAFA;
  --gray200: #EFEFEE;
  --gray300: #CCC;
  --gray400: #D7D8D7;
  --gray500: #BBBCBB;
  --gray600: #7A7B7A;
  --gray700: #535452;
  --gray800: #3b3c3b;
  --gray900: #282828;
  --green100: #DEF8D4;
  --green200: #A7D396;
  --red-light: #f00;
  --red: #DC3545;
  --red-dark: #ce0505;
  --listbox-bg: #d5d5d7;
  --danger: #FF0C0C;
  --warning: #FFC107;

  //sizes
  --top-navbar-height: 60px;
}