.title {
  color: var(--gray900);
}

.text {
  color: var(--gray900);
}

.primary {
  .title {
    color: var(--primary-color);
  }

  .text {
    color: var(--primary-color);
  }
}

.error {
  .title {
    color: var(--red-light);
  }

  .text {
    color: var(--red);
  }
}

.inverted {
  .title {
    color: var(--inverted-secondary-color);
  }

  .text {
    color: var(--inverted-secondary-color);
  }
}

.light {
  .title {
    color: var(--gray600);
  }

  .text {
    color: var(--gray600);
  }
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.size_xs {
  .title {
    font: var(--font-weigth-bold) var(--font-s);
  }

  .text {
    font: var(--font-weigth-normal) var(--font-xs);
  }
}

.size_s {
  .title {
    font: var(--font-weigth-bold) var(--font-m);
  }

  .text {
    font: var(--font-weigth-normal) var(--font-s);
  }

  .bold {
    font-weight: var(--font-weigth-bold);
  }
}

.size_m {
  .title {
    font: var(--font-weigth-bold) var(--font-l);
  }

  .text {
    font: var(--font-weigth-normal) var(--font-m);
  }

  .bold {
    font-weight: var(--font-weigth-bold);
  }
}

.size_l {
  .title {
    font: var(--font-weigth-bold) var(--font-xl);
  }

  .text {
    font: var(--font-l);
  }

  .bold {
    font-weight: var(--font-weigth-bold);
  }
}

.ellipsis {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}