.btnWrapper {
  display: contents;
}

.Button {
  cursor: pointer;
  color: var(--primary-color);
  padding: 4px 8px;
  border-radius: 4px;

  &:hover {
    opacity: 0.8;
    fill-opacity: 0.8;
    stroke-opacity: 0.8;
  }
}

.clear {
  padding: 0;
  border: none;
  background: none;
  outline: none;
}

.clearInverted {
  padding: 0;
  border: none;
  background: none;
  outline: none;
  color: var(--inverted-primary-color);
}

.outline {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  background: none;

  &:hover {
    transition: all 200ms ease-out;
    background-color: var(--primary-color);
    color: var(--inverted-secondary-color);

    @media (max-width: 480px) {
      color: var(--primary-color);
      background: none;
    }
  }
}

.outline_red {
  border: 1px solid var(--red-light);
  color: var(--red-light);
  background: none;
}

.background {
  border: 1px solid var(--primary-color);
  background: var(--primary-color);
  color: var(--white-bg-color);
}

.background_inverted {
  border: 1px solid var(--secondary-color);
  background: var(--secondary-color);
  color: var(--white-bg-color);
}

.square {
  padding: 0;
}

.square.size_m {
  width: var(--font-line-m);
  height: var(--font-line-m);
}

.square.size_l {
  width: var(--font-line-l);
  height: var(--font-line-l);
}

.square.size_xl {
  width: var(--font-line-xl);
  height: var(--font-line-xl);
}

.size_m {
  font: var(--font-m);
}

.size_l {
  font: var(--font-l);
}

.size_xl {
  font: var(--font-xl);
}

.disabled {
  opacity: 0.5;
}

.fullWidth {
  width: 100%;
}

.isGrabbing {
  cursor: grabbing !important;
}

.ellipsis {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}