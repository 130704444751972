.navbar {
  background: var(--gray800);
  width: 110px;
  height: 100vh;

  &.isRecon {
    background: var(--primary-color);
  }
}

.logoWrapper {
  background-color: var(--primary-color);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  border-bottom: 1px solid var(--gray800);
}