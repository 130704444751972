.active {
  box-shadow: -3px 0px 0px 0px #3B3C3B inset;
  background-color: var(--gray400);

  &:hover {
    background-color: var(--gray200);
  }
}

.lampIcon {
  font-size: 1.2rem;
}

.navGroupLight {
  font-size: 0.7rem;
  vertical-align: text-bottom;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 2px;
  margin-left: 0.3rem;
}