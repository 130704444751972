.Card {
  padding: 8px;
  border-radius: 4px;
}

.normal {
  background: var(--white-bg-color);
}

.outlined {
  border: 1px solid var(--gray200);
}

.active {
  border: 1px solid var(--primary-color200);
}

.max {
  width: 100%;
}
