.status {
  border-radius: 4px;
  width: 16px;
  height: 16px;
}
.work {
  background: var(--gray200);
}
.warning {
  background: var(--warning);
}
.alarm {
  background: var(--danger);
}