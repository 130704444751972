@import "styles/themes/pandora";
@import "styles/themes/recon";

$gray_100: #fafafa;
$gray_200: #efefee;
$gray_300: #ccc;
$gray_800: #3b3c3b;
$gray_secondary: #898c87;
$green_primary: #41a62b;
$blue_primary: #3B57A0;
$red_danger: #ff0000;
$white_primary: #ffffff;

html,
body {
  background-color: #f1f1f1;
  height: 100%;
  font-size: 16px;
}

#mount-point {
  height: 100%;
}

.container-fluid {
  padding: 0;
  margin: 0;
}

.login-page {
  height: 100%;
  background-image: url('assets/img/auth_back.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.login-page-recon {
  height: 100%;
  background-image: url('assets/img/recon_back.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.login-form {
  margin: auto;
  margin-top: 20%;
  padding: 20px;
  width: 500px;
  height: 200px;
  background-image: url('assets/img/auth_form_back.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;
  form {
    width: 310px;
    top: 30px;
    left: 150px;
    position: absolute;
    input {
      margin-bottom: 10px;
    }
  }
  .form-group {
    width: 100px;
  }
  .login-logo {
    width: 100px;
    display: inline-block;
    top: 30px;
    left: 30px;
    position: absolute;
  }
}
.login-form-recon {
  margin: auto;
  margin-top: 20%;
  padding: 20px;
  width: 500px;
  height: 200px;
  background-image: url('assets/img/auth_form_back.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;

  form {
    width: 210px;
    top: 30px;
    left: 250px;
    position: absolute;
    input {
      margin-bottom: 10px;
    }
  }
  .form-group {
    width: 100px;
  }
  .login-logo {
    width: 200px;
    display: inline-block;
    top: 30px;
    left: 30px;
    position: absolute;
  }
}

.nav {
  margin-top: 20px;
  margin-bottom: 20px;

  &-main {
    width: 110px;
    margin-top: 0px;
    flex-wrap: nowrap;
  }
}

.logout {
  font-size: small;
  background: transparent;
  color: #f93154;
  border: 1px solid #f93154;
  border-radius: 4px;
  margin-bottom: 20px;
  text-transform: uppercase;

  &:hover {
    background: #f93154;
    color: #1b222a;
  }
}

.mainmap {
  margin: 0;
  padding: 0;
}

.grouplist {
  background-color: white;
  padding: 0;
  // border-radius: 0 6px 6px 0;
  color: white;
  border-right: 1px solid $gray_300;
  margin-left: 110px;
  min-width: 255px;
}

.mainview {
  // margin-left: 3px;
  // padding: 0;
}

.events-table {
  height: 100%;
  padding: 5px;
  background: #f1f1f1;
}

.events-table .events-table-row {
  display: grid;
  grid-template-columns: 200px 200px 300px 100px auto;
  grid-auto-rows: minmax(30px, auto);
  border-bottom: 1px solid #878787;
  height: 30px;
}

.events-table .events-table-row .events-col-1 {
  grid-column: 1;
  grid-row: 1;
}

.events-table .events-table-row .events-col-2 {
  grid-column: 2;
  grid-row: 1;
}

.events-table .events-table-row .events-col-3 {
  grid-column: 3;
  grid-row: 1;
}

.events-table .events-table-row .events-col-4 {
  grid-column: 4;
  grid-row: 1;
}

.events-table .events-table-row .events-col-5 {
  grid-column: 5;
  grid-row: 1;
}

.events-table .events-table-row .events-col-345 {
  grid-column: 3/5;
  grid-row: 1;
}

.manageview-plcMainInfo {
  display: flex;
  justify-content: space-between;
  background-color: rgba(green, 0.03);
  padding: 0.5rem;
  border-radius: 0.5rem;
  flex-wrap: wrap;
  gap: 1rem;

  &__primary {
    display: flex;
    justify-content: space-between;
    flex-grow: 2;
    border-radius: 0.5rem;
  }

  &__secondary {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-radius: 0.5rem;
    min-width: 350px;
    gap: 1rem;
  }
}

.plcMainInfo,
.lightMainInfo {
  min-width: 500px;
  display: flex;
  flex-direction: column;
  border: 1px solid $gray_300;
  border-radius: 0.5rem;
  padding: 1rem;
  flex-grow: 2;

  &-item,
  &-title {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid transparent;
    border-bottom: 1px solid $gray_300;
    align-items: center;
  }

  &-item:last-child {
    border-bottom: 0px;
  }

  &-title {
    padding-bottom: 1rem;
    border: 0;

    &__name {
      font-size: large;
      font-weight: bold;
    }

    &__link {
      color: var(--primary-color);
      border-radius: 5px;
      padding: 0.1rem;

      &:hover {
        cursor: pointer;
        color: darken($color: $green_primary, $amount: 10);
        background-color: $gray_100;
      }
    }
  }

  &-item {
    min-height: 30px;

    &__name {
      color: $gray_secondary;
    }

    &__value {
    }
  }

  &-itemColumns {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    &__name {
      width: 100%;
      color: $gray_secondary;
      padding-top: 0.5rem;
    }

    &__value {
      display: flex;
      flex-direction: column;
      border: 1px solid $gray_300;
      border-radius: 0.5rem;
      width: 80px;
      overflow: hidden;
    }

    &__valueTitle {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 30px;
      background-color: $gray_200;
    }

    &__valueData {
      position: relative;
      display: flex;
      align-items: center;
      min-height: 30px;
      justify-content: center;

      & > i {
        position: absolute;
        top: -1px;
        right: 2px;
        font-size: smaller;
      }
    }

    &__bigSvg {
      width: 30px;
      height: 30px;
    }

    &__midSvg {
      width: 20px;
      height: 20px;
    }
  }
  &-ellipsis {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.plcMainInfoConnect,
.plcMainInfoDocs {
  display: flex;
  flex-direction: column;
  border: 1px solid $gray_300;
  padding: 1rem;
  border-radius: 0.5rem;

  &-item,
  &-title {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid grey;
  }

  &-title {
    padding: 1rem 0;

    border: 0;

    &__name {
      font-size: large;
      font-weight: bold;
    }

    &__link {
      font-size: large;
      color: var(--primary-color);
    }
  }
}

input[type='radio']:focus {
  outline: none !important;
  box-shadow: none !important;
}

input:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}

.form-switch .form-check-input:checked[type='checkbox']:after {
  background-color: var(--primary-color);
  outline: none !important;
}

.form-switch .form-check-input:checked[type='checkbox']:before {
  content: unset;
}

.form-check-input[type='checkbox']:checked {
  background-color: var(--primary-color);
  // background-color: lighten($green_primary, 10%);
  outline: none !important;
}

.form-check-input[type='checkbox']:checked:focus {
  background-color: var(--primary-color);
  outline: none !important;
}

.form-switch .form-check-input:focus {
  box-shadow: none !important;
}

.plcSettingsInfoItem {
  padding: 0.5rem;
  margin-bottom: 1.5rem;
  border: 1px solid $gray_300;
  width: fit-content;
  border-radius: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  &-title {
    font-size: large;
    font-weight: bold;

    .bi:hover {
      color: #41a62b;
      cursor: pointer;
    }
  }
  &-itemOutputs {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    min-height: 34px;

    & button {
      padding: 0 5px !important;
    }
  }

  &-item table {
    width: fit-content;
    text-align: center;
    margin-top: 0.5rem;

    tr:first-child {
      background-color: $gray_100;
    }

    td {
      padding: 0.1rem 0.5rem;

      input {
        width: 400px;
      }
    }
  }

  input[type='text'] {
    border-radius: 8px;
    border: 1px solid #ccc;
    outline: none;
    padding-left: 10px;
  }

  &-radios {
    button {
      padding: 0 5px !important;
    }

    label {
      margin-left: 0.5rem;
    }
  }
}

.manageview-table {
  height: calc(100% - 76px);
  padding: 16px 12px;
  background: #fff;
  margin: 1rem;
  border-radius: 8px 8px 0 0;
  min-width: min-content;
}

.manageview-table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  box-shadow: 0px 2px 0px 0px rgb(0 0 0 / 10%);
  padding: 6px;
  padding-bottom: 16px;
  border-radius: 8px;

  &__title {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;

    &__id {
      font-size: 0.7rem;
      color: #fff;
      background: $gray_secondary;
      padding: 0 4px;
      border-radius: 8px;
    }

    & .bi {
      font-size: 1.4rem;
      color: #4f4f4f;
      cursor: pointer;
    }

    & .bi:hover {
      color: var(--primary-color);
    }

    .noLink:hover {
      cursor: default;
      color: #4f4f4f;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-evenly;
    width: 100px;

    & .bi {
      font-size: 1.4rem;
      color: #4f4f4f;
      cursor: pointer;
    }

    & .bi:hover {
      color: var(--primary-color);
    }

    & .bi.bi-trash:hover {
      color: red;
    }
  }
}

.manageview-table .manageview-table-row {
  display: grid;
  grid-template-columns: 200px 200px 100px 100px 1fr;
  grid-auto-rows: minmax(50px, auto);
  border-bottom: 1px solid #878787;
}

.manageview-table .manageview-table-row .manageview-table-1 {
  grid-column: 1;
  grid-row: 1;
}

.manageview-table .manageview-table-row .manageview-table-2 {
  grid-column: 2;
  grid-row: 1;
}

.manageview-table .manageview-table-row .manageview-table-3 {
  grid-column: 3;
  grid-row: 1;
}

.manageview-table .manageview-table-row .manageview-table-4 {
  grid-column: 4;
  grid-row: 1;
}

.manageview-table .manageview-table-row .manageview-table-5 {
  grid-column: 5;
  grid-row: 1;
}

.manageview-table .manageview-table-row .manageview-table-345 {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row: 1;
}

.manageview-table .manageview-table-row .manageview-table-12345 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row: 1;
  margin-bottom: 5px;
}

.manageview-table .manageview-table-row .manageview-table-2-12345 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row: 2;
}

.report-table {
  // width: 100%;
  padding: 30px 20px;
  margin: 5px;
  background: #fff;
  border: 1px solid #878787;

  // div {border: 1px solid black}

  .report-table-row {
    display: grid;
    grid-template-columns: 20px auto 100px 100px 20px;
    grid-auto-rows: minmax(50px, auto);

    .report-table-header {
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row: 1;
      padding: 10px;
      text-align: center;
    }

    .report-table-left {
      grid-column-start: 1;
      grid-column-end: 5;
      grid-row: 1;
    }
  }
  .report-table-subgroup-row {
    display: grid;
    grid-template-columns: 20px 100px 60px 60px 60px 60px 60px 60px 60px 60px 60px auto;
    grid-auto-rows: minmax(30px, auto);
    border: 1px solid black;
    margin-bottom: 20px;
    justify-content: space-between;

    .report-table-subgroup-name {
      grid-column-start: 1;
      grid-column-end: 13;
      grid-row: 1;
      background: #000000;
      color: #fff;
      padding-left: 20px;
    }

    .report-table-subgroup-head-3-7 {
      grid-column-start: 3;
      grid-column-end: 7;
      grid-row: 2;
      text-align: center;
    }

    .report-table-subgroup-head-8-12 {
      grid-column-start: 8;
      grid-column-end: 12;
      grid-row: 2;
      text-align: center;
    }

    .report-table-subgroup-head-3-12 {
      grid-column-start: 3;
      grid-column-end: 12;
      grid-row: 2;
      display: grid;
      grid-template-columns: 60px 60px 60px 60px 60px 60px 60px 60px 60px;
      grid-auto-rows: minmax(30px, auto);
      font-size: 12px;
      text-align: center;
      justify-content: space-between;

      .report-table-subgroup-head1 {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row: 1;
      }

      .report-table-subgroup-head2 {
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row: 2;
      }
      .report-table-subgroup-head3 {
        grid-column-start: 4;
        grid-column-end: 6;
        grid-row: 2;
      }
      .report-table-subgroup-head4 {
        grid-column-start: 6;
        grid-column-end: 8;
        grid-row: 2;
      }
    }

    .report-table-subgroup-head-3-6 {
      grid-column-start: 3;
      grid-column-end: 6;
      grid-row: 2;
      display: grid;
      grid-template-columns: 60px 60px 60px;
      grid-auto-rows: minmax(30px, auto);
      font-size: 12px;
      text-align: center;
      justify-content: space-between;

      .report-table-subgroup-head1 {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row: 1;
      }

      .report-table-subgroup-head2 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row: 2;
      }
      .report-table-subgroup-head3 {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row: 2;
      }
      .report-table-subgroup-head4 {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row: 2;
      }
    }
    .report-table-subgroup-head-6-9 {
      grid-column-start: 6;
      grid-column-end: 9;
      grid-row: 2;
      display: grid;
      grid-template-columns: 60px 60px 60px;
      grid-auto-rows: minmax(30px, auto);
      font-size: 12px;
      text-align: center;
      justify-content: space-between;

      .report-table-subgroup-head1 {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row: 1;
      }

      .report-table-subgroup-head2 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row: 2;
      }
      .report-table-subgroup-head3 {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row: 2;
      }
      .report-table-subgroup-head4 {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row: 2;
      }
    }

    .report-table-subgroup-head-9-12 {
      grid-column-start: 9;
      grid-column-end: 12;
      grid-row: 2;
      display: grid;
      grid-template-columns: 60px 60px 60px;
      grid-auto-rows: minmax(30px, auto);
      font-size: 12px;
      text-align: center;
      justify-content: space-between;

      .report-table-subgroup-head1 {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row: 1;
      }

      .report-table-subgroup-head2 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row: 2;
      }
      .report-table-subgroup-head3 {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row: 2;
      }
      .report-table-subgroup-head4 {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row: 2;
      }
    }

    .report-table-subgroup-data {
      grid-column-start: 2;
      grid-column-end: 13;
      display: grid;
      grid-template-columns: 100px 60px 60px 60px 60px 60px 60px 60px 60px 60px auto;
      grid-auto-rows: minmax(30px, auto);
      font-size: 12px;
      text-align: right;
      justify-content: space-between;

      .report-table-subgroup-data-1-2 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row: 1;
      }
      .report-table-subgroup-data-2-3 {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row: 1;
        text-align: center;
      }
      .report-table-subgroup-data-3-4 {
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row: 1;
        text-align: center;
      }
      .report-table-subgroup-data-4-5 {
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row: 1;
        text-align: center;
      }
      .report-table-subgroup-data-5-6 {
        grid-column-start: 5;
        grid-column-end: 6;
        grid-row: 1;
        text-align: center;
      }
      .report-table-subgroup-data-6-7 {
        grid-column-start: 6;
        grid-column-end: 7;
        grid-row: 1;
        text-align: center;
      }
      .report-table-subgroup-data-7-8 {
        grid-column-start: 7;
        grid-column-end: 8;
        grid-row: 1;
        text-align: center;
      }
      .report-table-subgroup-data-8-9 {
        grid-column-start: 8;
        grid-column-end: 9;
        grid-row: 1;
        text-align: center;
      }
      .report-table-subgroup-data-9-10 {
        grid-column-start: 9;
        grid-column-end: 10;
        grid-row: 1;
        text-align: center;
      }
      .report-table-subgroup-data-10-11 {
        grid-column-start: 10;
        grid-column-end: 11;
        grid-row: 1;
        text-align: center;
      }

      .report-table-subgroup-data-2-6 {
        grid-column-start: 2;
        grid-column-end: 6;
        grid-row: 1;
        text-align: center;
      }

      .report-table-subgroup-data-3-5 {
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row: 1;
        text-align: center;
      }

      .report-table-subgroup-data-5-7 {
        grid-column-start: 5;
        grid-column-end: 7;
        grid-row: 1;
        text-align: center;
      }

      .report-table-subgroup-data-7-12 {
        grid-column-start: 7;
        grid-column-end: 12;
        grid-row: 1;
        text-align: center;
      }

      .report-table-subgroup-data-7-9 {
        grid-column-start: 7;
        grid-column-end: 9;
        grid-row: 1;
        text-align: center;
      }
    }
  }
}

.mainview-table {
  height: calc(100% - 76px);
  padding: 16px 12px;
  background: #fff;
  margin: 1rem;
  border-radius: 8px 8px 0 0;
  min-width: min-content;

  &-header {
    font-weight: bold;

    &-ViewAll {
      cursor: pointer;
      color: #878787;
      text-decoration: underline;
      font-size: 16px;
    }
  }
  .head {
     background: #FAFAFA;
     border-radius: 10px;
  }
  &-events {
    display: grid;
    grid-template-columns: 40px 80px 60px 40px 200px 120px 100px auto;
    grid-auto-rows: minmax(30px, auto);
    align-items: center;
    div {
      padding: 5px;
      // border: 1px solid black;
      text-align: left;
    }
    .status {
      margin: 1rem;
      border-radius: 4px;
      width: 16px;
      height: 16px;
    }
    .work {
      background: #EFEFEE;
    }
    .warning {
      background: #FFC107;
    }
    .alarm {
      background: #DC3545;
    }
    .order-link {
      color: #41A62B;
    }
    .order-link:hover {
      cursor: pointer;
      color: #3c662e;
    }
    .change-all-ev {
      color: #41A62B;
      font-weight: normal;
    }
    .change-all-ev:hover {
      cursor: pointer;
      font-weight: bold;
    }



    .col1 {
      grid-column: 1;
      grid-row: 1;
      text-align: center;
    }
    .col2 {
      grid-column: 2;
      grid-row: 1;
      text-align: center;
    }
    .col3 {
      grid-column: 3;
      grid-row: 1;
      text-align: center;
    }
    .col4 {
      grid-column: 4;
      grid-row: 1;
      text-align: center;
    }
  }
}
.filters {
  .form-check-label {
    font-weight: bold;
  }
  .chk-title {
    color: #7A7B7A;
  }
  .filter-bottom {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
.time-filter {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  position: relative;
  width: 100%;
  .filter-type{
    display:inline-block;
    width: 50%;
    height: 40px;
    padding: 10px;
  }
  .filter-type:hover {
    cursor: pointer;
    color: #41A62B;
    border-bottom: 4px solid #41A62B;
  }
  .selected {
    color: #41A62B;
    border-bottom: 4px solid #41A62B;
  }
  .filter-calendar{
    margin-top: 10px;
  }
  .filter-relative{
    margin-top: 10px;
  }

  filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.15));
}

.mainview-table .mainview-table-row {
  display: grid;
  grid-template-columns: 200px 160px 90px 50px auto;
  grid-auto-rows: minmax(30px, auto);
  border-bottom: 1px dotted #878787;
  align-items: center;
}

.mainview-table .mainview-table-row .mainview-table-1 {
  grid-column: 1;
  grid-row: 1;
  text-align: center;
}

.mainview-table .mainview-table-row .mainview-table-2 {
  grid-column: 2;
  grid-row: 1;
  text-align: center;
}

.mainview-table .mainview-table-row .mainview-table-3 {
  grid-column: 3;
  grid-row: 1;
  text-align: center;
}

.mainview-table .mainview-table-row .mainview-table-4 {
  grid-column: 4;
  grid-row: 1;
  text-align: left;
}

.mainview-table .mainview-table-row .mainview-table-5 {
  grid-column: 5;
  grid-row: 1;
}

.mainview-table .mainview-table-row .mainview-table-345 {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row: 1;
  text-align: center;
}

.mainview-table .mainview-table-row .mainview-table-12345 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row: 1;
  margin-bottom: 5px;
}

.mainview-table .mainview-table-row .mainview-table-2-12345 {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row: 2;
}

.mainview-table .mainview-table-row:nth-of-type(odd) {
  background: #e8ecef;
}

.mainview-table .mainview-table-row:nth-of-type(even) {
  background: #f1f1f1;
}

.mainview-table-row-header {
  background-color: #d7dbde !important;
  border-bottom: 1px solid black !important;
}

.mainview-table :last-child > .mainview-table-row.mainview-table-row-item {
  border-radius: 0 0 10px 10px !important;
}

.mainview-table .table > :not(caption) > * > * {
  padding: 1rem 0rem !important;
}

.mainview-table .table .logicGroup {
  font-weight: bold;
  font-size: larger;
}

.mainview-table .table tbody tr td {
  padding: 0rem 0rem !important;
}

.mainview-table table .thBottom {
  border-bottom: 2px solid black !important;
  padding: 0px !important;
}

.main_user {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  flex-direction: column;
  margin-left: calc(110px + 1rem);

  & .user_cards {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    input {
      margin-bottom: 20px;
    }
    input[type="email"] {
      margin-bottom: 0;
    }
    input[type="phone"] {
      margin-bottom: 0;
    }
    .confirm_state {
      font-size: small;
      margin-bottom: 10px;
    }
    .warn {
      color: #b75631;
    }
  }

  & .user_card {
    color: #000;
    border: 1px solid lightgray;
    padding: 20px;
    min-width: 350px;
    border-radius: 20px;
    box-shadow: 0 0 20px 5px rgba(34, 60, 80, 0.2);
  }
}

.main_status {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  flex-direction: column;
  margin-left: calc(110px + 1rem);

  & .status_forms {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  & .status_form {
    margin: 10px;
    min-width: 200px;
    width: auto;
  }
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

.custom-popup {
  width: 600px;
}

.pls-popup {
  width: 620px;
}

.custom-popup .leaflet-popup-content-wrapper {
  background: transparent;
  color: #fff;
  font-size: 12px;
  line-height: 24px;
  border-radius: 10px;
}

.custom-popup .leaflet-popup-content-wrapper a {
  color: rgba(255, 255, 255, 0.1);
}

.custom-popup .leaflet-popup-tip-container {
  width: 30px;
  height: 15px;
}

.custom-popup .leaflet-popup-content {
  margin: 0;
}

.custom-popup .leaflet-popup-tip {
  background: transparent;
  border: none;
  box-shadow: none;
}

.custom-popup .light-popup {
  padding: 0;
}

.custom-popup .light-popup .header {
  font-size: 14px;
  width: 100%;
  background: #2f40a4;
}

.main-graph {
  width: 700px;
}

.alarm-filter {
  height: 53px;

  &:hover {
    cursor: pointer;
  }
}

.mainview-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  width: 100%;
  height: 60px;
  background-color: #fff;
  padding: 0 16px;
  border-bottom: 1px solid $gray_300;

  & .dropdown > a {
    color: var(--primary-color);
    border-radius: 8px;
    border: 1px solid transparent;
  }

  & .isRecon > a {
    color: $blue_primary;
  }

  & .dropdown > a:hover {
    // background-color: rgba($color: #ccc, $alpha: 0.3);
    border: 1px solid rgba($color: #ccc, $alpha: 0.6);
  }
  &-dropdown-menu {
    width: 242px;
    border: 1px solid $gray_800;
    // right: 1rem !important;
    top: 6px !important;
  }

  & .dropdown-item:hover {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  &-exit {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-exit:hover.dropdown-item:hover {
    background-color: rgba(246, 220, 220, 0.818);
  }

  &-name {
    font-weight: bold;
    white-space: normal;
    font-size: medium;
  }

  &-role {
    color: #999;
  }
}

.header-search {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  background-color: #fff;
  border-bottom: 1px solid #ccc;

  &__input {
    width: 75%;
    padding-left: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    outline: none;

    &:focus {
      border-color: var(--primary-color);
    }
  }
}

.nav-item {
  margin: 0 auto;
  text-align: center;
}

//.menu-icon {
//  width: 40px;
//  height: 40px;
//  transition: all 400ms ease-out;
//
//  &:hover {
//    transform: scale(1.1, 1.1);
//    transition: all 100ms ease-out;
//  }
//
//  & g {
//    fill: #888;
//    transition: all 400ms ease-out;
//  }
//
//  &:hover g {
//    fill: $green_primary;
//    transition: all 100ms ease-out;
//  }
//}
//
//.menu-title {
//  color: #888;
//  transition: all 400ms ease-out;
//
//  &-active {
//    color: #ffffff;
//    transition: all 400ms ease-out;
//  }
//
//  &:hover {
//    transition: all 100ms ease-out;
//  }
//}
//
//.activeMenuLink .menu-icon,
//.activeMenuLink .menu-title {
//  transform: scale(1.2, 1.2);
//  transition: all 400ms ease-out;
//  cursor: default;
//}
//
//.activeMenuLink .menu-icon g {
//  fill: white;
//  transition: all 400ms ease-out;
//}

.custom-popup .leaflet-popup-content-wrapper {
  margin: 10px;
  background-color: white;
  color: black;
}

.order_status {
  width: 30px;
  text-align: center;
  color: #838783;
  margin: 5px;
}
.order_status:hover {
  cursor: pointer;
  color: #626662;
}

.SD_file_status {
  width: 30px;
  text-align: center;
  color: #838783;
  margin: 5px;
  background: gainsboro;
  padding: 4px;
  border-radius: 25%;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.SD_file_status:hover {
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 1);
  background: #ededed;
  color: #626662;
}
.modalSD {
  width: 600px;
  max-width: none !important;
}

.SD_file,
.SD_file__title {
  color: var(--primary-color);
  border-radius: 5px;
  max-width: 65vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    cursor: pointer;
    color: darken($color: $green_primary, $amount: 10);
    background-color: $gray_100;
  }

  &:hover .SD_file__title {
    color: darken($color: $green_primary, $amount: 10);
  }
}

.SD_file__title {
  margin: 0;
  padding: 0.5rem;
}

.modalCalendar {
  width: auto !important;
}
.eventGroup-del {
  color: red;
  padding-right: 10px;
}
.eventGroup-del:hover {
  cursor: pointer;
}
.eventGroup-edit:hover {
  cursor: pointer;
}
.eventGroup-time {
  padding-right: 10px;
}
.modalYS {
  width: 1500px;
  max-width: none !important;

  .YS_row > td {
    padding: 10px 0;
    width: fit-content;
  }

  .YS_cell {
    font-size: 12px;
    width: 40px;
    text-align: center;
    padding: 10px 0;
  }

  .YS_cell:hover {
    cursor: pointer;
  }

  .YearSheduleActions {
    width: 960px;
    margin-top: 10px;
  }

  .YS_override {
    .YSA_del {
      color: red;
    }

    .YSA_add {
      color: green;
    }

    span:hover {
      cursor: pointer;
    }
  }

  .YSA_cell_buttons {
    width: 30px;
    text-align: center;

    .YSA_del {
      color: red;
    }

    .YSA_add {
      color: green;
    }

    span:hover {
      cursor: pointer;
    }
  }

  .YSA_cell_type {
    width: 140px;
    text-align: center;
    align-content: center;

    select {
      width: 140px;
    }

    option {
      width: 140px;
    }
  }

  .YSA_cell_number {
    width: 160px;
    text-align: center;

    input {
      width: 40px;
    }

    input[type='checkbox'] {
      margin-top: 3px;
      padding-top: 3px;
    }

    label {
      font-size: 12px;
      display: inline-block;
    }
  }

  .YSA_cell_when {
    width: 230px;

    select {
      width: 230px;
    }

    option {
      width: 230px;
    }
  }

  .YSA_cell_hour {
    width: 100px;

    input {
      width: 40px;
    }
  }

  .YSA_cell_minute {
    width: 100px;

    input {
      width: 40px;
    }
  }

  .YSA_cell_value {
    width: 200px;

    select {
      width: 200px;
    }

    div {
      width: 200px;
    }
  }

  .YSA_warning {
    margin: 10px;
    color: orangered;
  }

  .YSA_calendar {
    span {
      color: #007bff;
      margin-left: 10px;
    }

    span:hover {
      cursor: pointer;
    }
  }

  .YSA_override_type {
    display: flex;
    align-items: center;

    input[type='radio'] {
      margin-right: 5px;
      margin-top: -5px;
    }
  }
}

.pls_params_modal {
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 1000;
  width: 222px;
  padding: 10px;
  font-size: 12px;
  border-radius: 4px;
  background-color: #fff;

  .close_button {
    position: absolute;
    top: 3px;
    right: 7px;
  }

  label {
    font-size: 14px;
    color: darkgreen;
  }

  .pls_state {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-column-gap: 1px;
    grid-row-gap: 1px;
    font-size: 12px;

    .state_title {
      grid-column: 1;
      border-bottom: 1px solid black;
    }

    .state_content {
      grid-column: 2;
      border-bottom: 1px solid black;
    }

    .state_table_content {
      grid-column: 2;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      border-bottom: 1px solid black;
      font-size: 12px;

      .table {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 0 0 36px;
        font-size: smaller;

        div {
          text-align: center;
        }

        input {
          width: 50px;
        }

        .plc-control-input-labels {
          padding: 0 5px;
          display: flex;
          font-size: 12px;
          justify-content: space-between;
        }

        .rele {
          font-size: 12px;
        }
      }
    }
  }
}
.rele-column {
  .table {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 0 0 36px;
    font-size: smaller;

    div {
      text-align: center;
    }

    input {
      width: 50px;
    }

    .plc-control-input-labels {
      padding: 0 5px;
      display: flex;
      font-size: 12px;
      justify-content: space-evenly;
    }

    .rele {
      font-size: 12px;
      max-width: fit-content;
      margin: 0 auto;
    }
  }
}

.pls_state,
.light_state {
  //div{border: 1px solid black}
  font-size: 14px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-column-gap: 2px;
  grid-row-gap: 2px;

  .state_title {
    grid-column: 1;
    border-bottom: 1px solid black;
  }

  .state_content {
    grid-column: 2;
    border-bottom: 1px solid black;
  }

  .state_table_content {
    grid-column: 2;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-bottom: 1px solid black;

    .table {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex: 0 0 100px;

      div {
        text-align: center;
      }

      input {
        width: 50px;
      }

      .plc-control-input-labels {
        padding: 0 5px;
        display: flex;
        font-size: 12px;
        justify-content: space-between;
      }

      .rele {
        font-size: 12px;
      }
    }
  }

  // for old slider
  .state_table_wide_content {
    width: 450px;
    position: relative;
    border-bottom: 1px solid black;
    .left_arrow {
      position: absolute;
      left: 0;
      width: 20px;
      height: 60px;
      padding: 0;
      color: var(--primary-color);
    }
    .right_arrow {
      position: absolute;
      left: 430px;
      width: 20px;
      height: 60px;
      padding: 0;
      color: var(--primary-color);
    }
    .wide_content_wrap {
      width: 400px;
      overflow: hidden;
      height: 140px;
      position: absolute;
      left: 30px;
      .wrap {
        position: absolute;
        left: 0;
        grid-column: 2;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        transition-property: left;
        transition-duration: 1s;
        .table {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          flex: 0 0 100px;
          div {
            text-align: center;
          }
          input {
            width: 50px;
          }
          .plc-control-input-labels {
            padding: 0 5px;
            display: flex;
            font-size: 12px;
            justify-content: space-between;
          }
          .rele {
            font-size: 12px;
          }
        }
      }
    }
  }
  .rele_content {
    height: 140px;
  }
  .ci_content {
    height: 80px;
  }
  // end old slider
}

.state_table_wide_content {
  width: 100%;
  position: relative;

  .left_arrow {
    position: absolute;
    left: 0;
    width: 20px;
    height: 60px;
    padding: 0;
    color: var(--primary-color);
    top: 1px;
    border: 2px solid #41a62b;
    background-color: $gray_100;

    &:disabled {
      border: 2px solid transparent;
    }
  }
  .right_arrow {
    position: absolute;
    right: 0;
    width: 20px;
    height: 60px;
    padding: 0;
    color: var(--primary-color);
    top: 1px;
    border: 2px solid #41a62b;
    background-color: $gray_100;

    &:disabled {
      border: 2px solid transparent;
    }
  }
  .wide_content_wrap {
    width: 92%;
    overflow: hidden;
    height: 140px;
    position: absolute;
    left: 30px;
    .wrap {
      position: absolute;
      left: 0;
      grid-column: 2;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      transition-property: left;
      transition-duration: 1s;
      gap: 0.5rem;
      .table {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 0 0 100px;
        div {
          text-align: center;
        }
        input {
          width: 50px;
        }
        .plc-control-input-labels {
          padding: 0 5px;
          display: flex;
          font-size: 12px;
          justify-content: space-between;
        }
        .rele {
          font-size: 12px;
        }
      }
    }
  }
}
.rele_content {
  height: 60px;
}
.ci_content {
  height: 60px;
}

.controlEntranceNames {
  th {
    padding: 0 10px;
  }
  td {
    padding: 0 10px;
    text-align: center;
  }
}

.statusOutputGroup {
  width: 100%;
  min-width: 180px;
  min-height: 56px;
  margin-right: 5px;
  border-spacing: 6px 3px;
  border-collapse: initial;
  tr {
    background-color: #dddddd;
  }
  td {
    border-radius: 8px;
    background-color: #cacaca;
    width: 30%;
    padding: 0 5px !important;
    div {
      border-bottom: 1px solid black;
    }
  }
}

.light_state {
  width: 600px;
}

.leaflet-popup-content {
  .plc_state_tabs {
    width: 400px;
  }

  .pls_state {
    padding: 10px;
    font-size: 12px;
    grid-template-columns: 100px 460px;

    .state_table_content {
      font-size: 12px;

      .table {
        flex: 0 0 50px;
      }
    }
  }

  .light_state {
    padding: 10px;
    font-size: 12px;
    grid-template-columns: 100px 260px;
  }
}

.popover {
  z-index: 99999999 !important;
  line-height: 0 !important;
}

.custom-range {
  width: 100%;
}

.DS_cell {
  font-size: 14px;
  display: grid;
  grid-template-columns: 50px 30px 15px 30px 10px;
  grid-template-rows: 25px 25px 25px 25px;
  grid-column-gap: 2px;
  grid-row-gap: 5px;
  align-items: center;

  input {
    width: 30px;
    height: 40px;
    padding-right: 2px;
    border: 1px solid black;
    border-radius: 10%;
    text-align: center;
  }

  span {
    font-size: 16px;
  }

  .off_title {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column: 1;
  }

  .off_hours_val {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column: 2;
  }

  .off_hours_val_up {
    grid-row: 1;
    grid-column: 3;
    margin-top: 10px;
  }

  .off_hours_val_down {
    grid-row: 2;
    grid-column: 3;
    margin-top: -11px;
  }

  .off_minutes_val {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column: 4;
  }

  .off_minutes_val_up {
    grid-row: 1;
    grid-column: 5;
    margin-top: 10px;
  }

  .off_minutes_val_down {
    grid-row: 2;
    grid-column: 5;
    margin-top: -11px;
  }

  .on_title {
    grid-row-start: 3;
    grid-row-end: 5;
    grid-column: 1;
  }

  .on_hours_val {
    grid-row-start: 3;
    grid-row-end: 5;
    grid-column: 2;
  }

  .on_hours_val_up {
    grid-row: 3;
    grid-column: 3;
    margin-top: 10px;
  }

  .on_hours_val_down {
    grid-row: 4;
    grid-column: 3;
    margin-top: -11px;
  }

  .on_minutes_val {
    grid-row-start: 3;
    grid-row-end: 5;
    grid-column: 4;
  }

  .on_minutes_val_up {
    grid-row: 3;
    grid-column: 5;
    margin-top: 10px;
  }

  .on_minutes_val_down {
    grid-row: 4;
    grid-column: 5;
    margin-top: -11px;
  }

  .DS_marker {
    color: #3040b5;
    font-size: 16px;
    border: 1px dotted transparent;
  }

  .DS_marker:hover {
    color: rgba(66, 83, 242, 0.62);
    cursor: pointer;
    border: 1px dotted black;
  }
}

.light-popup {
  margin: 10px;
  width: 410px;

  .plc-control {
    width: 340px;
  }
}

.plc-control {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-rows: 30px 1fr;
  grid-column-gap: 20px;
}

.plc-control-switch {
  grid-row-start: 2;
  align: center;
  position: relative;
}

.plc-control-switch input {
  width: 40px;
  margin-left: 10px;
}

.plc-control-switch .plc-control-input-labels {
  display: flex;
  font-size: 12px;
  justify-content: space-between;
}

.plc-control-dimmer-label {
  grid-row-start: 1;
  grid-column-start: 2;
}

.plc-control-dimmer {
  grid-row-start: 2;
}

.plc-control-dimmer .plc-control-input-labels {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
}

.nav-groups {
  height: calc(100vh - 60px);
  overflow: auto;

  &.full-height {
    height: 100vh;
  }
}

.nav-group {
  padding-left: 0;
  width: 100%;

  & svg {
    vertical-align: text-top;
    margin-right: 4px;
  }

  .isActive {
    box-shadow: -3px 0px 0px 0px #3B3C3B inset;
    background-color: var(--gray400);

    &:hover {
      background-color: var(--gray200);
    }
  }
}

.nav-group h2 {
  font-size: 16px;
}

.nav-group:hover {
  cursor: pointer;
}

.nav-group .add-group {
  margin-bottom: 2px;
  padding-top: 2px;
  height: 30px;
  background-color: rgba(169, 169, 169, 0.31);
}
.nav-group-active {
  background-color: rgba(239, 239, 238, 1);
}

.nav-group-title {
  font-size: 16px;
  margin-bottom: 2px;
  min-height: 50px;
  display: grid;
  grid-template-columns: 26px 1fr 40px 40px;
  align-items: center;
  justify-items: start;
  i {
    font-size: 20px;
    color: black;
  }
  .nav-group-name {
    color: black;
    padding: 4px;
  }
  .nav-group-name-open {
    color: rgba(65, 166, 43, 1);
  }

}

.nav-group-title:hover,
.nav-group-work:hover,
.nav-group-warning:hover,
.nav-group-alarm:hover,
.nav-group-lights-item:hover {
  background-color: rgba(239, 239, 238, 1);
  i {
    color: rgba(65, 166, 43, 1);
  }
  .nav-group-name {
    color: rgba(65, 166, 43, 1);
  }

  &.isRecon {
    i {
      color: $blue-primary;
    }
    .nav-group-name {
      color: $blue-primary;
    }
  }
}

.nav-group-title-open {
  i {
    color: rgba(65, 166, 43, 1);
  }
  .nav-group-name {
    color: rgba(65, 166, 43, 1);
  }

  &.isRecon {
    i {
      color: $blue-primary;
    }
    .nav-group-name {
      color: $blue-primary;
    }
  }
}

.chevronBtn {
  cursor: pointer;
}

.nav-group-control {
  font-size: 20px;
  text-align: center;
}


.nav-group-name__find {
  color: white;
  padding: 4px;
  background: #562678;
  border-radius: 10px 10px;
}

.nav-group-count {
  min-width: 30px;
  background-color: rgba(137, 140, 135, 1);
  border-radius: 4px;
  border: 0;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 14px;
  text-align: center;
  color: white;
}

.nav-group-alarm-count {
  background-color: white;
  border-radius: 50%;
  border: 2px solid;
  height: 30px;
  width: 30px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 4px;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  color: #c90808;
  border-color: #b50000;
}

.nav-group-warning-count {
  background-color: white;
  border-radius: 50%;
  border: 2px solid;
  height: 30px;
  width: 30px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 4px;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  color: #ffd461;
  border-color: #cc9500;
}

.nav-group-admin {
  background-color: rgba(169, 169, 169, 0.31);
}

.nav-group-lights-list {
  background-color: rgba(169, 169, 169, 0.31);
}

.nav-group-lights {
  background-color: rgba(169, 169, 169, 0.31);
  height: 35px;
  display: grid;
  grid-template-columns: 2fr 1fr 20px 1fr 30px;
  grid-template-rows: 15px 15px;
  grid-row-gap: 2px;
}

.nav-group-light-active {
  span {
    color: rgba(65, 166, 43, 1);
  }
  i {
    color: rgba(65, 166, 43, 1);
  }
}
.nav-group-lights-item {
  font-size: 16px;
  color: black;
  margin-bottom: 2px;
  min-height: 50px;
  grid-column: 1 / 5;
  grid-row: 1 / 2;
  display: grid;
  align-content: center;

  &__light {
    font-size: 0.7rem;
    vertical-align: text-bottom;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 2px;
    margin-left: 0.3rem;
  }

  &__comment,
  &__withoutComment {
    font-size: 1rem;
  }

  & svg {
    vertical-align: text-top;
    margin-top: 1px;
  }
}

.nav-group-lights-search {
  grid-column: 1;
  grid-row: 1 / 2;
  text-align: right;
  padding-top: 3px;
  position: relative;
}

.nav-group-lights-head-id {
  grid-column: 2;
  grid-row: 1 / 2;
  text-align: right;
  padding-top: 3px;
}

.nav-group-lights-head-id-up {
  grid-column: 3;
  grid-row: 1;
  line-height: 0;
}

.nav-group-lights-head-id-down {
  grid-column: 3;
  grid-row: 2;
  line-height: 0;
}

.nav-group-lights-head-comment {
  grid-column: 4;
  grid-row: 1 / 2;
  text-align: right;
  padding-top: 3px;
}

.nav-group-lights-head-comment-up {
  grid-column: 5;
  grid-row: 1;
  line-height: 0;
}

.nav-group-lights-head-comment-down {
  grid-column: 5;
  grid-row: 2;
  line-height: 0;
}

.nav-group-alarm {
  background-color: #c90808;
}

.nav-group-alarm .nav-group-alarm-count {
}

.nav-group-warning {
  background-color: #ffd461;
}

.nav-group-warning .nav-group-warning-count {
  color: #ffd461;
  border-color: #cc9500;
}

.nav-group-work {
  background-color: #25c286;
}

.navbar-vert {
  background: $gray_800;
  width: 110px;
  z-index: 1000;
  position: fixed;

  &-logo {
    background-color: #41a62b;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 110px;
    border-bottom: 1px solid $gray_800;

    &_inner {
      margin: 0 auto;
      width: 40px;

      & > svg g {
        fill: white;
      }
    }
  }
}

.h100vh {
  height: 100vh;
}

.nav-main-logout {
  // order: 1;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
}

.imgPreview {
  //margin: 10px 10px;
  width: 100%;
  border: 1px solid gray;
}

.imgPreview img {
  width: 100%;
  height: 100%;
}

.schemeMarker {
  color: #007b00;

  &:hover {
    cursor: pointer;
  }
}

.navLightOrderMarker {
  color: #fff;
  margin: 0 3px;

  &:hover {
    cursor: pointer;
  }
}

.scriptControlMarker {
  color: #2fcd4b;
  margin: 0 3px;

  &:hover {
    cursor: pointer;
  }
}

.scriptAddCmd {
  color: #2fcd4b;
  border: 1px solid grey;
  border-radius: 4px;
  position: relative;
  padding: 6px;
  margin-top: 10px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
}

input:focus {
  border-color: var(--primary-color) !important;
  box-shadow: inset 0px 0px 0px 1px var(--primary-color) !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.btn {
  margin: 0.375rem;
  padding: 0.5rem 1.5rem;

  &-primary {
    background-color: var(--primary-color);

    &:hover {
      background-color: darken($green_primary, 10%);
    }
  }

  &-outline-primary {
    color: var(--primary-color);
    border-color: var(--primary-color);
    padding: 6px 22px !important;

    &:active,
    &:focus {
      background-color: transparent;
      color: var(--primary-color);
      border-color: var(--primary-color);
    }

    &:hover {
      background-color: var(--primary-color);
      color: #fff;
      border-color: darken($green_primary, 20%);
    }

    &:disabled {
      color: $gray_800;
      border: 2px solid $gray_300;
      background-color: $gray_300;
      padding: 6px 22px !important;
    }
  }

  &-outline-danger {
    color: $red_danger;
    border-color: $red_danger;
    padding: 6px 22px !important;

    &:hover {
      background-color: $red_danger;
      color: #fff;
      border-color: darken($red_danger, 20%);
    }
  }
}

.nav-tabs .nav-link.active,
input[type='range'],
input[type='radio'] {
  color: var(--primary-color);
  border-color: var(--primary-color);
  fill: var(--primary-color);
  accent-color: var(--primary-color);
}

input[type='range'],
input[type='radio'] {
  color: var(--primary-color);
  border-color: var(--primary-color);
  fill: var(--primary-color);
  accent-color: var(--primary-color);
  background-color: var(--primary-color);
}

.btn-serverGroup1 {
  background-color: #3882a7;
}

.btn-serverGroup2 {
  background-color: #00bf17;
}

.btn-serverGroup3 {
  background-color: #ff7b60;
}

.btn-serverGroup4 {
  background-color: #b04abf;
}

.btn-serverGroup5 {
  background-color: #bf2222;
}

.btn-serverGroup6 {
  background-color: #3cbf80;
}

.btn-serverGroup7 {
  background-color: #3f13bf;
}

.btn-serverGroup8 {
  background-color: #e3e525;
}

.success-message {
  color: #00bf17;
}

.error-message {
  color: #bf2222;
}

.current-page {
  font-size: 1rem;
  vertical-align: middle;
  line-height: 0.75;
}

.manage-light-control {
  border-bottom: 1px solid #878787;
}

.light-map-control {
  width: 300px;
}
.event-comment {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  .comment-time {
    width: 140px;
    font-weight: bold;
  }
  .comment-body {
    padiing-left: 10px;
  }
}
.pagination-pages {
  width: 100%;
  float: left;
  height: 8vh;
  text-align: center;
  padding-top: 1vh;
  padding-bottom: 1vh;
  border-bottom: 1px black solid;
}
// Override some Bootstrap pagination styles
ul.pagination {
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  li.page-item.active {
    a.page-link {
      color: saturate(darken(#ced4da, 50%), 5%) !important;
      background-color: saturate(lighten(#ced4da, 7.5%), 2.5%) !important;
      border-color: #ced4da !important;
    }
  }

  a.page-link {
    padding: 0.75rem 1rem;
    min-width: 3.5rem;
    text-align: center;
    box-shadow: none !important;
    border-color: #ced4da !important;
    color: saturate(darken(#ced4da, 30%), 10%);
    font-weight: 900;
    font-size: 1rem;

    &:hover {
      background-color: lighten(desaturate(#ced4da, 50%), 12.5%);
    }
  }
}

.react-datepicker__time-list {
  width: 99% !important;
}

.react-datepicker-wrapper {
  width: 70%;
}

.modal {
  z-index: 1070;
  overflow-x: auto;
}

.swal2-container {
  z-index: 9999 !important;
}

.modal-open {
  overflow: inherit;
}

.swal2-styled.swal2-confirm {
  background-color: #4285f4;
}

.swal2-styled.swal2-cancel {
  background-color: #f93154;
}

.btn:disabled {
  opacity: 0.5;
}

.highcharts-range-selector-group {
  display: none;
}

// @media (max-width: 1140px) {
//   .nav {
//     &-main {
//       position: initial;
//     }
//   }
// }

.mr-8 {
  margin-right: 8px;
}

.ml-8 {
  margin-left: 8px;
}

.offcanvas-end {
  width: 600px;
}

.offcanvas-header {
  background-color: $gray_100;
  padding: 1.5rem;
}

.offcanvas-title {
  font-size: 1.5rem;
}

.offcanvas-body {
  padding: 1.5rem;
}

.modalMnemo {
  width: 1200px;
  max-width: none !important;

  .mnemo_container {
    min-width: 1200px;
    min-height: 950px;
    position: relative;
    padding: 0;

    .mnemo_back_once {
      position: absolute;
      top: 0;
      left: 0;
      width: 1160px;
      height: 933px;
      background-image: url('assets/img/mnemo_back.png');
      background-repeat: no-repeat;
      background-position: -19px -19px;
      z-index: 2;
    }

    .mnemo_back {
      position: absolute;
      top: 0;
      left: 0;
      width: 1160px;
      height: 933px;
      background-image: url('assets/img/mnemo_back_recon.png');
      background-repeat: no-repeat;
      background-position: -19px -19px;
      z-index: 2;
    }

    .mnemo_switch {
      position: absolute;
      top: 410px;
      left: 140px;
      width: 162px;
      height: 180px;
      z-index: 3;
    }
    .pos0 {
      background: url('assets/img/mnemo_asset.png') no-repeat -27px -173px;
    }
    .switch_title_pos0_on {
      position: absolute;
      left: 176px;
      top: 387px;
      font-size: 18px;
      color: #FF0000;
    }
    .switch_title_pos0_off {
      position: absolute;
      left: 176px;
      top: 387px;
      font-size: 18px;
      color: #000000;
    }
    .pos1 {
      background: url('assets/img/mnemo_asset.png') no-repeat -220px -173px;
    }
    .switch_title_pos1_on {
      position: absolute;
      left: 234px;
      top: 387px;
      font-size: 18px;
      color: #319828;
    }
    .switch_title_pos1_off {
      position: absolute;
      left: 234px;
      top: 387px;
      font-size: 18px;
      color: #000000;
    }
    .pos2 {
      background: url('assets/img/mnemo_asset.png') no-repeat -414px -173px;
    }
    .pos3 {
      background: url('assets/img/mnemo_asset.png') no-repeat -608px -173px;
    }
    .switch_title_pos3_on {
      position: absolute;
      left: 305px;
      top: 531px;
      font-size: 18px;
      color: #319828;
    }
    .switch_title_pos3_off {
      position: absolute;
      left: 305px;
      top: 531px;
      font-size: 18px;
      color: #000000;
    }

    .mnemo_door {
      position: absolute;
      top: 71px;
      left: 275px;
      width: 62px;
      height: 40px;
      z-index: 3;
    }
    .closed {
      background: url('assets/img/mnemo_asset_old.png') no-repeat -327px -73px;
    }
    .open {
      background: url('assets/img/mnemo_asset_old.png') no-repeat -395px -73px;
    }

    .mnemo_signal {
      position: absolute;
      width: 34px;
      height: 26px;
      z-index: 3;
    }
    .green {
      background: url('assets/img/mnemo_asset.png') no-repeat -654px -81px;
    }
    .gray {
      background: url('assets/img/mnemo_asset.png') no-repeat -750px -81px;
    }
    .red {
      background: url('assets/img/mnemo_asset.png') no-repeat -701px -81px;
    }

    .signal_door {
      top: 78px;
      left: 380px;
    }

    .signal_rele_1 {
      top: 252px;
      left: 578px;
    }

    .signal_rele_2 {
      top: 252px;
      left: 612px;
    }

    .signal_rele_once {
      top: 252px;
      left: 609px;
    }

    .signal_battery {
      top: 222px;
      left: 720px;
    }

    .signal_kontaktor {
      top: 600px;
      left: 510px;
    }

    .signal_auto9 {
      top: 792px;
      left: 1056px;
    }
    .signal_auto8 {
      top: 742px;
      left: 1056px;
    }
    .signal_auto7 {
      top: 694px;
      left: 1056px;
    }
    .signal_auto6 {
      top: 558px;
      left: 1058px;
    }
    .signal_auto5 {
      top: 508px;
      left: 1058px;
    }
    .signal_auto4 {
      top: 460px;
      left: 1058px;
    }

    .mnemo_rele {
      position: absolute;
      width: 48px;
      height: 48px;
      z-index: 3;

      &.once {
        top: 675px;
        left: 650px;
      }

      &.first {
        top: 445px;
        left: 662px;
      }

      &.second {
        top: 675px;
        left: 662px;
      }

      &:hover {
        cursor: pointer;
      }

      &.disabled {
        opacity: .9;
      }
    }
    .rele_on {
      background: url('assets/img/mnemo_asset.png') no-repeat -135px -94px;

      &.info {
        width: 48px;
        height: 48px;
        z-index: 3;
      }
    }
    .rele_off {
      background: url('assets/img/mnemo_asset_v2.png') no-repeat -21px -94px;

      &.info {
        width: 48px;
        height: 48px;
        z-index: 3;
      }
    }
    .mnemo_rele_offline {
      position: absolute;
      width: 48px;
      height: 48px;
      top: 687px;
      left: 660px;
      z-index: 3;
    }
    .offline {
      background: url('assets/img/mnemo_asset.png') no-repeat -750px -81px;

      &.info {
        width: 48px;
        height: 26px;
        z-index: 3;
      }
    }

    .info_mnemo_rele {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 230px;
      position: absolute;
      top: 24px;
      left: 900px;
      z-index: 3;
    }

    .info_mnemo_rele_block {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .voltage {
      position: absolute;
      left: 440px;
      font-size: 16px;
      font-weight: bold;
      z-index: 3;
    }
    .l1 {
      top: 105px;
    }
    .l2 {
      top: 130px;
    }
    .l3 {
      top: 155px;
    }
    .batt {
      left: 792px;
      top: 302px;
      font-size: 18px;
    }


    .meter {
      position: absolute;
      left: 260px;
      font-size: 16px;
      font-weight: bold;
      z-index: 3;
    }
    .p1 {
      top: 697px;
    }
    .p2 {
      top: 726px;
    }
    .p3 {
      top: 755px;
    }
    .psum {
      top: 874px;
    }

    .ethernet_off {
      top: 23px;
      left: 580px;
      position: absolute;
      width: 30px;
      height: 40px;
      z-index: 3;
      background: url('assets/img/mnemo_asset.png') no-repeat -169px -20px;
    }
    .ethernet_on {
      top: 23px;
      left: 580px;
      position: absolute;
      width: 30px;
      height: 40px;
      z-index: 3;
      background: url('assets/img/mnemo_asset.png') no-repeat -216px -20px;
    }

    .mnemo_antenna {
      position: absolute;
      width: 18px;
      height: 40px;
      z-index: 3;
      background: url('assets/img/mnemo_asset.png') no-repeat -28px -24px;
    }
    .ant1 {
      top: 23px;
      left: 404px;
    }
    .ant2 {
      top: 23px;
      left: 474px;
    }

    .mnemo_antenna_level {
      position: absolute;
      width: 34px;
      height: 30px;
      z-index: 3;
    }
    .signal_100 {
      background: url('assets/img/mnemo_asset.png') no-repeat -124px -29px;
    }
    .signal_75 {
      background: url('assets/img/mnemo_asset.png') no-repeat -266px -29px;
    }
    .signal_50 {
      background: url('assets/img/mnemo_asset.png') no-repeat -314px -29px;
    }
    .signal_25 {
      background: url('assets/img/mnemo_asset.png') no-repeat -363px -29px;
    }
    .signal_0 {
      background: url('assets/img/mnemo_asset.png') no-repeat -411px -29px;
    }
    .level1 {
      top: 26px;
      left: 430px;
    }
    .level2 {
      top: 26px;
      left: 500px;
    }
  }
}
