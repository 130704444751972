.sidebar {
  background-color: var(--white-bg-color);
  padding: 0;
  color: var(--white-bg-color);
  border-right: 1px solid var(--gray300);
  min-width: 300px;
  width: 100%;
  height: 100%;
  transition: width 0.3s;

  .collapseBtn {
    padding: 0 10px;
    position: absolute;
    right: -22px;
    top: 95vh;
    z-index: 1000;
    border: 1px solid var(--gray400);
    background-color: var(--white-bg-color);
    color: #1b222a;
  }
}

.collapsed {
  min-width: 0;
  width: 0;

  .contentWrapper {
    display: none;
  }
}