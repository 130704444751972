.component {
  display: inline-flex;
  align-items: flex-start;
  cursor: pointer;
}

.box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin: 2px;
  color: var(--inverted-secondary-color);
  background-color: var(--inverted-secondary-color);
  border: 1.5px solid #8a8a8e;
  border-radius: 4px;
  transition: background-color 0.2s ease, border-color 0.2s ease;
  box-sizing: border-box;
  overflow: hidden;
}

.checkedIcon {
  position: absolute;
  color: var(--inverted-secondary-color);
  fill: var(--inverted-secondary-color);
}

.component:hover:not(.disabled) .box {
  background-color: #c5c5c7;
  border-color: #8a8a8e;
}

.component:active:not(.disabled) .box {
  background-color: #c5c5c7;
  border-color: #000;
}

.checked .box {
  background-color: var(--primary-color);
  border-color: transparent;
}

.checked:hover:not(.disabled) .box {
  background-color: var(--primary-color-shade);
  border-color: transparent;
}

.checked:active:not(.disabled) .box {
  background-color: var(--primary-color-shade);
  border-color: transparent;
}

.indeterminate .box {
  background-color: var(--primary-color);
  border-color: transparent;
}

.indeterminate:hover:not(.disabled) .box {
  background-color: var(--primary-color-shade);
  border-color: transparent;
}

.indeterminate:active:not(.disabled) .box {
  background-color: var(--primary-color-shade);
  border-color: transparent;
}

.disabled .box {
  background-color: var(--inverted-secondary-color);
  border-color: rgb(198, 198, 199);
}

.disabled.checked .box {
  background-color: #FFF;
  border-color: transparent;
}

.disabled.indeterminate .box {
  background-color: #FFF;
  border-color: transparent;
}

.checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.indeterminateLine {
  position: absolute;
  width: 10px;
  height: 2px;
  background-color: var(--inverted-secondary-color);
}