.NavBarItem {
  .icon:not(.active) {
    color: var(--gray600);
  }
  &:hover {
    .title {
      color: var(--primary-color);
      transition: all 100ms ease-out;

      &.isRecon {
        color: var(--white-bg-color);
      }
    }
    .icon {
      transform: scale(1.1, 1.1);
      transition: all 100ms ease-out;
      color: var(--primary-color);

      &.isRecon {
        color: var(--white-bg-color);
      }
    }

    .icon g {
      transition: all 100ms ease-out;
    }
  }
}

.title {
  color: var(--gray600);
  transition: all 400ms ease-out;

  &.active {
    color: var(--inverted-secondary-color);
    transform: scale(1.1, 1.1);
    transition: all 400ms ease-out;
  }
}

