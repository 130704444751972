.InputWrapper {
  display: flex;
}

.fullWidth {
  width: 100%;
}

.input {
  background: transparent;
  border: 1px solid var(--gray400);
  border-radius: 3px;
  outline: none;
  width: 100%;
  color: var(--gray600);

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-size: 14px;
  }

  &.s {
    padding: 4px 8px;
    font-size: 14px;
  }

  &.m {
    padding: 4px 8px;
    font-size: 16px;
  }

  &.l {
    padding: 8px 12px;
    font-size: 18px;
  }
}

.withAddonRight {
  border-right: none;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.readonly {
  opacity: 0.7;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.01;
  }

  100% {
    opacity: 1;
  }
}

.iconWrapper {
  background-color: var(--primary-color);
  padding: 3.5px 8px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.isInvalid {
  border: 1px var(--red-dark) solid;
}

.label {
  display: flex;
  gap: 4px;
  &.required:after {
    content: '*';
    color: var(--red-dark);
  }
}
