.navGroup {
  padding-left: 0;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  & svg {
    vertical-align: text-top;
    margin-right: 4px;
  }

  & h2 {
    font-size: 16px;
  }
}

.active {
  box-shadow: -3px 0px 0px 0px #3B3C3B inset;
  background-color: var(--gray400);

  &:hover {
    background-color: var(--gray200);
  }
}

.navGroupTitle {
  font-size: 16px;
  margin-bottom: 2px;
  min-height: 50px;
  display: grid;
  grid-template-columns: 26px 1fr 40px 40px;
  align-items: center;
  justify-items: start;
  i {
    font-size: 20px;
    color: black;
  }
  .name {
    color: black;
    padding: 4px;
  }
  .nameOpen {
    color: var(--primary-color);
  }
}