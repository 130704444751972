.spinner {
  text-align: center;

  &.s {
    width: 20px;
    > div {
      width: 4px;
      height: 4px;
    }
  }

  &.m {
    width: 30px;
    > div {
      width: 8px;
      height: 8px;
    }
  }

  &.l {
    width: 40px;
    > div {
      width: 12px;
      height: 12px;
    }
  }
}

.spinner > div {
  background-color: currentColor;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
}
