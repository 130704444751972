.cardWrapper {
  &.horizontal {
    min-width: 300px;
  }
  &.vertical {
    width: 100%;

    &.isWrap {
      flex-wrap: wrap;
    }
  }
}

.card {
  padding: 0 12px;
  min-width: 100px;
}