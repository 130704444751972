.Tag {
  padding: 4px 8px;
  border-radius: 4px;
  color: var(--inverted-secondary-color);
}

.lightDefault {
  background-color: var(--gray200);
  color: var(--gray900);
}

.default {
  background-color: var(--secondary-color);
}

.error {
  background-color: var(--danger);
}

.warning {
  background-color: var(--warning);
}

.border {
  border: 1px solid var(--gray400);
}

.icon {
  width: 10px;
  height: 10px;
  cursor: pointer;
}