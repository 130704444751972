@import "reset";
@import "variables/global";
@import "themes/pandora";
@import "themes/recon";
@import "../assets/css/react-datepicker.css";

.app {
  background: var(--bg-color);
  min-height: 100vh;
  font-family: var(--font-family-main);
}