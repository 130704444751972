.Flex {
  display: flex;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyCenter {
  justify-content: center;
}

.justifyEnd {
  justify-content: flex-end;
}

.justifyBetween {
  justify-content: space-between;
}

.justifyAround {
  justify-content: space-around;
}

.alignStart {
  align-items: flex-start;
}

.alignCenter {
  align-items: center;
}

.alignEnd {
  align-items: flex-end;
}

.directionRow {
  flex-direction: row;
}

.directionColumn {
  flex-direction: column;
}

.gap4 {
  gap: 4px;
}

.gap8 {
  gap: 8px;
}

.gap12 {
  gap: 12px;
}

.gap16 {
  gap: 16px;
}

.gap24 {
  gap: 24px;
}

.gap32 {
  gap: 32px;
}

.max {
  width: 100%;
}

.nowrap {
  flex-wrap: nowrap;
}

.wrap {
  flex-wrap: wrap;
}

.wrapReverse {
  flex-wrap: wrap-reverse;
}
