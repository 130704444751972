.navGroup {
  padding-left: 0;
  width: 100%;

  & svg {
    vertical-align: text-top;
    margin-right: 4px;
  }
}

.active {
  box-shadow: -3px 0px 0px 0px #3B3C3B inset;
  background-color: var(--gray400);

  &:hover {
    background-color: var(--gray200);
  }
}

.navGroupTitle {
  font-size: 16px;
  margin-bottom: 2px;
  min-height: 50px;
  display: grid;
  grid-template-columns: 26px 1fr 40px 40px;
  align-items: center;
  justify-items: start;
  color: black;
  &:hover {
    color: var(--primary-color);
  }
}

.isSearchedValue {
  color: white;
  padding: 4px;
  background: #562678;
  border-radius: 10px 10px;
}

.isOpen {
  color: var(--primary-color);
}

.navGroupName {
  max-width: 100%;
  padding: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.controllerIcon {
  font-size: 1.4rem;
}