.LightsLayout {
  min-height: 100vh;
}

.header {
  position: sticky;
  top: 0;
  height: 59px;
  background-color: var(--white-bg-color);
  z-index: 1000;
}

.content {
  grid-area: content;
  justify-self: center;
  padding: 16px 24px;
  width: 100%;
}