.sliderWrapper {
  position: relative;
  width: 100%;
  margin: auto;
  direction: ltr;
}

.contentWrap {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  width: 94%;
  height: auto;
}

.content{
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;

  transition-duration: 1s;
  transition-property: left;
}