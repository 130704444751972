.navGroupLights {
  background-color: rgba(169, 169, 169, 0.31);
  height: 35px;
  display: grid;
  grid-template-columns: 2fr 1fr 20px 1fr 30px;
  grid-template-rows: 15px 15px;
  grid-row-gap: 2px;
}

.navGroupLightSearch {
  grid-column: 1;
  grid-row: 1 / 2;
  text-align: right;
  padding-top: 3px;
  position: relative;
}

.navLightOrderMarker {
  position: absolute;
  top: 4px;
  left: 1px;
  color: rgba(169, 169, 169, 0.5);
  margin: 0 3px;

  &:hover {
    cursor: pointer;
  }
}