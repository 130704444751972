.counterCardWrapper {
  width: 525px;
}

.header {
  padding: 29px 24px;
  background-color: var(--gray100);
}

.close {
  fill: var(--secondary-color);
  color: var(--secondary-color);
}

.card {
  padding: 0 24px !important;
}