.MainLayout {
  min-height: 100vh;
  display: grid;
  grid-template-areas:
            'navbarvert sidebar content';
  grid-template-columns: 110px min-content 1fr;
}

.navbarvert {
  grid-area: navbarvert;
}

.sidebar {
  grid-area: sidebar;
  max-width: 400px;
  justify-self: center;
  width: 100%;
}

.content {
  grid-area: content;
  justify-self: center;
  padding: 16px 24px;
  width: 100%;
}

.navbarvert,
.sidebar {
  position: sticky;
  top: 0;
  height: 100vh;
}

.header {
  position: sticky;
  top: 0;
  height: 59px;
  background-color: var(--white-bg-color);
  z-index: 1000;
}