.verticalCard {
  padding: 0 !important;
}

.title {
  width: 100%;
  max-width: 100px;
  background-color: var(--gray100);
  border-bottom: 1px solid var(--gray200);
  font: var(--font-weigth-normal) var(--font-s);
  color: var(--gray600);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 4px 4px 0 0;

  &.active {
    background-color: var(--primary-color100);
    border-bottom: 1px solid var(--primary-color200);
  }
}