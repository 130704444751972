.tabs {
  display: flex;
  justify-content: space-between;
  width: 100%;

  li {
    flex-grow: 1;
    margin-bottom: 12px;

    button {
      width: 100%;
      text-align: left;
    }
  }
}